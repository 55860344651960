import $ from "jquery";
import toastr from "toastr";
import "select2";
import "jquery-highlighttextarea";
import "./main";
// import './ckeditor_loader'
// import 'ckeditor4'
// You can uncomment and adjust CKEDITOR as needed.

// For global usage
global.$ = $;
global.jQuery = $;
global.toastr = toastr;

import "../stylesheets/application.scss";

require("@rails/ujs").start();
// require("turbolinks").start()
require("@rails/activestorage").start();
require("channels");
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
