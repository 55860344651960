$(function () {
  $(document).on(
    "mouseleave",
    ".select2-container .select2-results__option",
    function () {
      $(this).removeClass("select2-results__option--highlighted");
    }
  );

  $('[data-tooltip="tooltip"]').tooltip({
    trigger: "hover",
    html: true,
  });

  $(document).on("select2:open", (e) => {
    const selectId = e.target.id;
    $(
      ".select2-search__field[aria-controls='select2-" + selectId + "-results']"
    ).each((key, value) => {
      value.focus();
    });
  });

  $(".alert").fadeOut(5000, () => {
    $(".alert").remove();
  });
});
$videoDetailTimeFormatRegex = /^\d{2}:\d{2}:\d{2}\.\d{3}$/;